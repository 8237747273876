<template><v-app><Header />
    <v-main>
      <v-container fluid>
<v-row><v-col>
    <v-col cols="12" align="center"><img src="../assets/img/logo_atas.png" height="200px"></v-col>
</v-col></v-row>
<v-row><v-col>
<v-card>
<v-row align="center">
    <v-col cols="12" md="3" align="center"><img src="../assets/img/logo_kiri.png" height="200px"></v-col>
    <v-col cols="12" md="6" align="center">
        <h1 class="d-none d-md-block">Perpustakaan Darul Fikri MAN 1 Lamongan</h1>
        <h3 class="d-md-none">Perpustakaan Darul Fikri MAN 1 Lamongan</h3>
    </v-col>
    <v-col cols="12" md="3" align="center"><img src="../assets/img/logo_kanan.png" height="200px"></v-col>
</v-row>
</v-card>
</v-col></v-row>

<v-row dense><v-col cols="12"><v-card>
	<v-card-title class="info text--info white--text">Profil Perpustakaan</v-card-title>
	<v-card-subtitle class="info text--info white--text">Darul Fikri MAN 1 Lamongan</v-card-subtitle>
	<v-card-text class="text--primary">
		<p>
		Perpustakaan Darul Fikri MAN 1 Lamongan berdiri di atas bangunan 8X21 m² = 168 m² yang mudah terjangkau bagi pemustaka. Perpustakaan ini berfungsi sebagai pusat informasi yang menyediakan, mengolah dan menyebarluaskan sumber – sumber informasi/bahan pustaka yang dibutuhkan oleh pemustaka untuk mendukung kegiatan pembelajaran di lingkungan MAN 1 Lamongan agar menghasilkan lulusan akademi berkualitas.
		</p>
		<p>
        Dalam kancah peraturan persaingan di dunia pendidikan, perpustakaan Darul Fikri MAN 1 Lamongan bertekad menjadi mitra profesional pemustaka untuk mendukung pelaksanaan program dengan memberikan layanan yang prima, didasari oleh sikap kepedulian dan dapat diakses dari manapun tanpa dibatasi ruang dan waktu waktu dengan melengkapi standar SNP dan pelayanan berbasis ICT.
		</p>
		<v-row class="d-flex justify-center"><v-col md="5" sm="12"><v-img src="../assets/img/a1686608157570.jpg" /></v-col></v-row>
		<p>
		Dalam mewujudkan visi madrasah yaitu “Terwujudnya madrasah digital ,unggul,  berkarakter, terampil dan berbudaya lingkungan", maka visi Perpustakaan Darul Fikri MAN 1 Lamongan adalah "Terwujudnya perpustakaan digital yang mampu memberikan pelayanan prima berbasis teknologi informasi dan komunikasi".
		</p>
		<p>
		Gerakan Literasi Madrasah adalah sebuah program intervensi pembudayaan literasi yang tepat, mudah dilaksanakan, dilakukan secara sistemik, komprehensif, merata pada semua komponen madrasah, berkelanjutan, dan dikelola secara profesional oleh lembaga yang kredibel
		</p>
		<v-row class="d-flex justify-center"><v-col md="5" sm="12"><v-img src="../assets/img/b1686608157693.jpg"/></v-col></v-row>
		<p>
		Membaca-menulis (literasi) merupakan salah satu aktivitas penting dalam hidup. Sebagian besar proses pendidikan bergantung pada kemampuan dan kesadaran literasi. Budaya literasi yang tertanam dalam diri pemustaka mempengaruhi tingkat keberhasilan baik di madrasah maupun dalam kehidupan bermasyarakat.
        </p>
        <p>
        Rendahnya budaya literasi belum dianggap sebagai masalah yang mendesak bagi warga madrasah, sehingga tidak muncul respon cepat yang diperlukan serta cenderung disepelekan. Persoalan teknis di lapangan masih ada siswa yang belum melaksanakan program literasi dengan sempurna yaitu setiap hari wajib membaca dan menuangkan ide pokok yang harus ditulis dalam buku program literasi. Perpustakaan Darul Fikri MAN 1 Lamongan melaksanakan gerakan literasi dengan program “Jambu Batu Sikat Petang” yang merupakan akronim dari pinjam buku, baca-tulis, prestasi meningkat, dan petik bintang. Adapun tujuan dari program tersebut adalah agar pemustaka aktif meminjam buku kemudian membaca dan menuliskan ide pokok atau intisarinya yang dilaksanakan setiap hari untuk meningkatkan prestasi yang unggul, sehingga memetik bintang keberhasilan sesuai harapan yang dicita-citakan.
        </p>
	</v-card-text>
</v-card></v-col></v-row>

<v-row><v-col cols="12"><v-card>
  <v-card-title class="info text--info white--text">VISI, MISI, DAN TUJUAN</v-card-title>
  <v-card-text class="text--primary">
    <p>
    Dalam menjalankan tugas dan fungsinya, Perpustakaan Darul Fikri MAN 1 Lamongan memiliki visi misi dan tujuan yang berpedoman pada visi, misi dan tujuan madrasah. Adapun visi, misi dan tujuan tersebut adalah sebagai berikut :
    </p>
    <b>a. Visi</b>
    <p>
      Dalam mewujudkan visi madrash yaitu <b>"Terwujudnya madrasah digital, unggul, terampil  dan berbudaya lingkungan"</b>, maka visi perpustakaan Darul Fikri MAN 1 Lamongan adalah "Terwujudnya perpustakaan digital yang mampu memberikan pelayanan prima berbasis teknologi informasi dan komunikasi".
    </p>
    <b>b. Misi</b>
    <p>
    Sesuai dengan sasaran mutu madrasah yaitu tersedianya sarana prasarana madrasah sesuai Standar Nasional Pendidikan (SNP) dan SNI dan terwujudnya peningkatan prestasi peserta didik, kompetensi tenaga pendidik dan tenaga kependidikan, maka perpustakaan Darul Fikri MAN 1 Lamongan akan melaksanakan hal-hal sebagai berikut :
    </p>
    <ol>
      <li>Meningkatkan fungsi perpustakaan sebagai pusat kegiatan pembelajaran, penelitian dan rekreatif</li>
      <li>Melengkapi sarana prasarana perpustakaan sesuai standar Nasional Pendidikan</li>
      <li>Meningkatkan pelayanan berbasis ICT</li>
      <li>Meningkatkan kenyamanan dan kepuasan pemustaka</li>
      <li>Meningkatkan budaya gemar membaca dengan berbagai aktivitas dan kreativitas</li>
    </ol>
    <br/>
    <b>c. Tujuan</b>
    <p>
    Untuk mengimplementasikan setiap misi yang telah terurai di atas, Perpustakaan MAN 1 Lamongan memiliki tujuan yang sesuai dengan tujuan madrasah yaitu Meningkatkan prestasi prestasi didik, kompetensi tenaga pendidik dan tenaga kependidikan dengan cara sebagai berikut :
    </p>
    <ol>
      <li>Memupuk dan menumbuhkembangkan minat serta bakat siswa dan guru untuk membaca dan menulis, memperkenalkan teknologi informasi, dan membiasakan mengakses informasi secara mandiri.</li>
      <li>Mendukung terselenggaranya proses belajar mengajar.</li>
      <li>Menyediakan berbagai sumber informasi terbaru dan berorientasi pada selera dan kebutuhan pemakai, sehingga program yang dilaksanakan dapat berlangsung dengan efektif.</li>
      <li>Optimalisasi layanan berbasis teknologi informasi dan komunikasi </li>
      <li>Mengembangkan kemampuan siswa dan guru untuk memecahkan berbagai masalah yang dihadapi, berdasarkan rasa tanggung jawab dan juga usaha sendiri.</li>
      <li>Memberikan layanan kepada pemustaka, meningkatkan kegemaran membaca, serta memperluas wawasan dan pengetahuan untuk mencerdaskan kehidupan bangsa.</li>
    </ol>
  </v-card-text>
</v-card></v-col></v-row>

<v-row><v-col cols="12"><v-card>
  <v-card-title class="info text--info white--text">JAM BUKA DAN LAYANAN PERPUSTAKAAN DARUL FIKRI MAN 1 LAMONGAN</v-card-title>
<v-card-text class="text--primary">
  <v-row class="d-flex justify-center"><v-col md="5" sm="12"><v-img src="../assets/img/c1686608157637.jpg"/></v-col></v-row>
</v-card-text>
</v-card></v-col></v-row>

<v-row><v-col cols="12"><v-card>
  <v-card-title class="info text--info white--text">TATA TERTIB PERPUSTAKAAN DARUL FIKRI MAN 1 LAMONGAN</v-card-title>
<v-card-text class="text--primary">
  <v-row class="d-flex justify-center"><v-col md="5" sm="12"><v-img src="../assets/img/d1686608157621.jpg"/></v-col></v-row>
</v-card-text>
</v-card></v-col></v-row>


<v-row><v-col cols="12"><v-card>
  <v-card-title class="info text--info white--text">PROSEDUR PENGGUNAAN PERPUSTAKAAN DIGITAL “E-LIB MANELA”</v-card-title>
<v-card-text class="text--primary">
  <v-row class="d-flex justify-center"><v-col md="5" sm="12"><v-img src="../assets/img/e1686608157592.jpg"/></v-col></v-row>
</v-card-text>
</v-card></v-col></v-row>


<v-row><v-col cols="12"><v-card>
  <v-card-title class="info text--info white--text">PROSEDUR PENGGUNAAN APLIKASI INLISLITE PERPUSTAKAAN DARUL FIKRI MAN 1 LAMONGAN</v-card-title>
<v-card-text class="text--primary">
  <v-row class="d-flex justify-center"><v-col md="5" sm="12"><v-img src="../assets/img/f1686608157606.jpg"/></v-col></v-row>
</v-card-text>
</v-card></v-col></v-row>


<v-row><v-col cols="12"><v-card>
  <v-card-title class="info text--info white--text">GALERI PERPUSTAKAAN DARUL FIKRI MAN 1 LAMONGAN</v-card-title>
<v-card-text class="text--primary">
  <v-row><v-col cols="6">
    <v-img src="../assets/img/kkii4324.jpg" />
  </v-col><v-col cols="6">
    <v-img src="../assets/img/g1686608157677.jpg"  />
  </v-col></v-row>

  <v-row><v-col cols="6">
    <v-img src="../assets/img/g1686608157710.jpg"/>
  </v-col><v-col cols="6">
    <v-img src="../assets/img/g1686608157727.jpg"/>
  </v-col></v-row>

  <v-row><v-col cols="6">
    <v-img src="../assets/img/gt1686608157743.jpg"/>
  </v-col><v-col cols="6">
    <v-img src="../assets/img/gt1686608157760.jpg"/>
  </v-col></v-row>


  <v-row><v-col cols="6">
    <v-img src="../assets/img/jk1686608157777.jpg"/>
  </v-col><v-col cols="6">
    <v-img src="../assets/img/gt1686608157760.jpg"/>
  </v-col></v-row>

</v-card-text>
</v-card></v-col></v-row>

<v-row><v-col cols="12"><v-card>
  <v-card-title class="info text--info white--text">ALAMAT PERPUSTAKAAN DARUL FIKRI MAN 1 LAMONGAN</v-card-title>
  <v-card-text class="text--primary">
  <v-row class="d-flex  justify-center"><v-col cols="6">
    Jl. Veteran No. 43, Jatis, Lamongan, Jawa Timur 6211<br/>
    <a href="https://goo.gl/maps/SJvNHmGQrcUncyVs7" target="blank"><v-img src="../assets/img/map.png"/></a>
    </v-col></v-row>
  </v-card-text>
</v-card></v-col></v-row>

<WebHome />
      </v-container>
      
    </v-main>
    
    <Footer />
  </v-app>
</template>
<script>
import Header from './layout/Header';
import Footer from './layout/Footer';
import WebHome from './WebHome';

  export default {
    components: { Header, WebHome, Footer }
  }
</script>
